/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "../styles/app.sass";

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
//import $ from 'jquery';

//console.log("Hello Webpack Encore! Edit me in assets/app.js");

var rellax = new Rellax(".rellax", {
  center: true,
});
AOS.init();


import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

CookieConsent.run({
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom left",
      equalWeightButtons: true,
      flipButtons: false
    },
    preferencesModal: {
      layout: "box",
      position: "right",
      equalWeightButtons: true,
      flipButtons: false
    }
  },
  categories: {
    necessary: {
      readOnly: true
    },
    functionality: {},
    analytics: {},
    marketing: {}
  },
  language: {
    default: "fr",
    translations: {
      en: {
        consentModal: {
          title: "Manage cookie consent",
          description: "To provide the best experiences, we use technologies such as cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behavior or unique IDs on this site. Failure to consent or withdrawing consent may negatively impact certain features and functions.",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          showPreferencesBtn: "Manage preferences",
          footer: "<a href=\"en/privacy-policy\">Privacy Policy</a>\n<a href=\"en/terms-and-conditions-of-use\">Terms and conditions</a>"
        },
        preferencesModal: {
          title: "Consent Preferences Center",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          savePreferencesBtn: "Save preferences",
          closeIconLabel: "Close modal",
          serviceCounterLabel: "Service|Services",
          sections: [
            {
              title: "Manage cookie consent",
              description: "To provide the best experiences, we use technologies such as cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behavior or unique IDs on this site. Failure to consent or withdrawing consent may negatively impact certain features and functions."
            },
            {
              title: "Functional <span class=\"pm__badge\">Always Enabled</span>",
              description: "Storage or technical access is strictly necessary for the purpose of legitimate interest of allowing the use of a specific service explicitly requested by the subscriber or Internet user, or for the sole purpose of carrying out the transmission of communication on an electronic communications network.",
              linkedCategory: "necessary"
            },
            {
              title: "Statistics",
              description: "Technical storage or access that is used exclusively for statistical purposes.",
              linkedCategory: "analytics"
            },
            {
              title: "Marketing",
              description: "Storage or technical access is necessary to create Internet user profiles in order to send advertisements, or to track the Internet user on a website or on several websites with similar marketing purposes.",
              linkedCategory: "marketing"
            }
          ]
        }
      },
      fr: {
        consentModal: {
          title: "Gérer le consentement aux cookies",
          description: "Pour offrir les meilleures expériences, nous utilisons des technologies telles que les cookies pour stocker et/ou accéder aux informations des appareils. Le fait de consentir à ces technologies nous permettra de traiter des données telles que le comportement de navigation ou les ID uniques sur ce site. Le fait de ne pas consentir ou de retirer son consentement peut avoir un effet négatif sur certaines caractéristiques et fonctions.",
          acceptAllBtn: "Tout accepter",
          acceptNecessaryBtn: "Tout rejeter",
          showPreferencesBtn: "Gérer les préférences",
          footer: "<a href=\"fr-ca/politiques-de-confidentialite\">Politique de confidentialité</a>\n<a href=\"fr-ca/conditions-d-utilisation\">Termes et conditions</a>"
        },
        preferencesModal: {
          title: "Préférences de cookies",
          acceptAllBtn: "Tout accepter",
          acceptNecessaryBtn: "Tout rejeter",
          savePreferencesBtn: "Sauvegarder les préférences",
          closeIconLabel: "Fermer la modale",
          serviceCounterLabel: "Services",
          sections: [
            {
              title: "Gérer le consentement aux cookies",
              description: "Pour offrir les meilleures expériences, nous utilisons des technologies telles que les cookies pour stocker et/ou accéder aux informations des appareils. Le fait de consentir à ces technologies nous permettra de traiter des données telles que le comportement de navigation ou les ID uniques sur ce site. Le fait de ne pas consentir ou de retirer son consentement peut avoir un effet négatif sur certaines caractéristiques et fonctions."
            },
            {
              title: "Fonctionnel <span class=\"pm__badge\">Toujours Activé</span>",
              description: "Le stockage ou l’accès technique est strictement nécessaire dans la finalité d’intérêt légitime de permettre l’utilisation d’un service spécifique explicitement demandé par l’abonné ou l’internaute, ou dans le seul but d’effectuer la transmission d’une communication sur un réseau de communications électroniques.",
              linkedCategory: "necessary"
            },
            {
              title: "Statistiques",
              description: "Le stockage ou l’accès technique qui est utilisé exclusivement à des fins statistiques.",
              linkedCategory: "analytics"
            },
            {
              title: "Marketing",
              description: "Le stockage ou l’accès technique est nécessaire pour créer des profils d’internautes afin d’envoyer des publicités, ou pour suivre l’internaute sur un site web ou sur plusieurs sites web ayant des finalités marketing similaires.",
              linkedCategory: "marketing"
            }
          ]
        }
      }
    },
    autoDetect: "document"
  }
});